iframe#video-modal {
    position: relative;
    margin: -50% auto 0 auto;
    top: 50%;
}

#vid-container {
    display: inline-block;
}

.accident-warning {
    text-align: center;
}

.center {
    text-align: center;
}

.accident-info {
    font-weight: 600 !important;
}

iframe {
    width: 100%;
    border: 0;
}

ion-modal#submit-modal {
    --ion-background-color: rgba(0, 0, 0, 0.3);
}

ion-card#submission-card {
    margin: auto;
    background-color: #c4c4c4;
    margin-left: 2vw;
    margin-right: 2vw;
}

.mt-15 {
    margin-top: 15px;
}

button.item-native {
    padding: 0;
}

button.picker-button.ion-activatable.sc-ion-picker-md {
    color: #3F77E2;
}

button.picker-opt.picker-opt-selected {
    color: #3F77E2;
}

button.alert-button.ion-focusable.ion-activatable.alert-button-role-cancel.sc-ion-alert-md {
    color: #3F77E2;
}

.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
    color: #fff;
    background-color: #666666;
}

.alert-radio-icon.sc-ion-alert-md {
    color: #3F77E2;
}

ion-card-header {
    background-color: #ffffff;
}

ion-input.sc-ion-input-md-h.sc-ion-input-md-s.md.has-value.hydrated {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    outline: 1px solid #666666;
}

ion-input#lp-inspection {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    outline: 1px solid #666666;
    color: #231F20;
    border-radius: 10px;
}

ion-input#western {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    outline: 1px solid #666666;
    color: #231F20;
    border-radius: 10px;
}

ion-datetime#western {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    outline: 1px solid #666666;
    color: #231F20;
    border-radius: 10px;
}

.mb-10 {
    margin-bottom: 10vh;
}

.mb-20 {
    margin-bottom: 20vh;
}


/* p {
    color: black !important;
} */

ion-item {
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    --ion-safe-area-left: 0;
}

ion-item.item.md.ion-focusable.hydrated.ion-color.ion-color-pitch.roadtest-category {
    --ion-safe-area-left: 16px;
}

.picker-wrapper.ion-overlay-wrapper.sc-ion-picker-md {
    color: #fff;
    background-color: #666666;
}

ion-label {
    color: black;
}

.mb-15 {
    margin-bottom: 15px;
}

.dis-if {
    display: inline-flex;
}

.days-input {
    padding-top: 0px !important;
    width: 50%;
    margin-left: 20px;
}

.checkBox-contain {
    text-align: center;
    display: flex;
    margin: auto;
}

.release-agreement {
    margin: 10px;
}

.fake-label {
    background: #262626;
}

.calender-form-day {
    z-index: 150;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.calender-form {
    z-index: 120;
    margin-left: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.calender-form-open {
    height: calc(36vh) !important;
}

.label-left {
    font-size: 17px;
    justify-content: left;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
}

.divider-divider {
    width: calc(100%) !important;
    background: #404040 !important;
    height: 1px !important;
    width: 100%;
    border: 0;
}

.image-screen {
    height: 100%;
    overflow-y: scroll !important;
}

.emphasis {
    font-style: italic;
}

.container {
    padding: 0vh 2vw 1vh 2vw;
    margin-bottom: 9vh;
    overflow: inherit;
}

#tire-diagram {
    width: 100%;
}

ion-label#black {
    color: #231F20;
}

li {
    color: #231F20;
}

ion-toggle {
    --background: rgb(155, 155, 155);
    --background-checked: #5f5f5f;
    --handle-background: #5f5f5f;
    --handle-background-checked: rgb(155, 155, 155);
}

.docbtn {
    background-color: transparent;
    font-size: 10pt;
    color: #3F77E2;
    text-decoration: underline;
}

.doc-view {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.trailer-loading {
    background: #222;
    color: #fff;
}

.label-centering {
    width: 100%;
    text-align: center;
}

.field-title {
    color: white;
    padding: 4px;
}

.blue {
    color: #208cff;
}

.green-button {
    background-color: #3F77E2;
    text-align: center;
    width: 100%;
}

.green-button-submit {
    background-color: #3F77E2;
}

:root {
    --ion-color-primary: #3F77E2;
    --ion-color-primary-rgb: 169, 239, 1;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #3F77E2;
    --ion-color-primary-tint: #3F77E2;
    --ion-color-secondary: #ffffff;
    --ion-color-secondary-rgb: 255, 255, 255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #e0e0e0;
    --ion-color-secondary-tint: #ffffff;
    --ion-color-tertiary: rgba(248, 248, 248, 0.79);
    --ion-color-tertiary-rgb: 18, 18, 18;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #101010;
    --ion-color-tertiary-tint: #2a2a2a;
    --ion-color-quarternary: #f8f8f8;
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-background-color: #DCDCDC;
    --ion-background-color-rgb: 220, 220, 220;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-color-gains: #dcdcdc;
    --ion-color-gains-rgb: 220, 220, 220;
    --ion-color-gains-contrast: #000000;
    --ion-color-gains-contrast-rgb: 0, 0, 0;
    --ion-color-gains-shade: #c2c2c2;
    --ion-color-gains-tint: #e0e0e0;
    --ion-color-whitesmoke: #f8f8f8;
    --ion-color-whitesmoke-rgb: 248, 248, 248;
    --ion-color-whitesmoke-contrast: #000000;
    --ion-color-whitesmoke-contrast-rgb: 0, 0, 0;
    --ion-color-whitesmoke-shade: #dadada;
    --ion-color-whitesmoke-tint: #f9f9f9;
    --ion-color-westerngrey: #666666;
    --ion-color-westerngrey-rgb: 102, 102, 102;
    --ion-color-westerngrey-contrast: #ffffff;
    --ion-color-westerngrey-contrast-rgb: 255, 255, 255;
    --ion-color-westerngrey-shade: #5a5a5a;
    --ion-color-westerngrey-tint: #757575;
    --ion-color-silver: #c4c4c4;
    --ion-color-silver-rgb: 196, 196, 196;
    --ion-color-silver-contrast: #000000;
    --ion-color-silver-contrast-rgb: 0, 0, 0;
    --ion-color-silver-shade: #acacac;
    --ion-color-silver-tint: #cacaca;
    --ion-color-pitch: #231F20;
    --ion-color-pitch-rgb: 35, 31, 32;
    --ion-color-pitch-contrast: #ffffff;
    --ion-color-pitch-contrast-rgb: 255, 255, 255;
    --ion-color-pitch-shade: #1f1b1c;
    --ion-color-pitch-tint: #393536;
}

.ion-color-pitch {
    --ion-color-base: var(--ion-color-pitch);
    --ion-color-base-rgb: var(--ion-color-pitch-rgb);
    --ion-color-contrast: var(--ion-color-pitch-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pitch-contrast-rgb);
    --ion-color-shade: var(--ion-color-pitch-shade);
    --ion-color-tint: var(--ion-color-pitch-tint);
}

.ion-color-silver {
    --ion-color-base: var(--ion-color-silver);
    --ion-color-base-rgb: var(--ion-color-silver-rgb);
    --ion-color-contrast: var(--ion-color-silver-contrast);
    --ion-color-contrast-rgb: var(--ion-color-silver-contrast-rgb);
    --ion-color-shade: var(--ion-color-silver-shade);
    --ion-color-tint: var(--ion-color-silver-tint);
}

.ion-color-gains {
    --ion-color-base: var(--ion-color-gains);
    --ion-color-base-rgb: var(--ion-color-gains-rgb);
    --ion-color-contrast: var(--ion-color-gains-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gains-contrast-rgb);
    --ion-color-shade: var(--ion-color-gains-shade);
    --ion-color-tint: var(--ion-color-gains-tint);
}

.ion-color-whitesmoke {
    --ion-color-base: var(--ion-color-whitesmoke);
    --ion-color-base-rgb: var(--ion-color-whitesmoke-rgb);
    --ion-color-contrast: var(--ion-color-whitesmoke-contrast);
    --ion-color-contrast-rgb: var(--ion-color-whitesmoke-contrast-rgb);
    --ion-color-shade: var(--ion-color-whitesmoke-shade);
    --ion-color-tint: var(--ion-color-whitesmoke-tint);
}

.ion-color-westerngrey {
    --ion-color-base: var(--ion-color-westerngrey);
    --ion-color-base-rgb: var(--ion-color-westerngrey-rgb);
    --ion-color-contrast: var(--ion-color-westerngrey-contrast);
    --ion-color-contrast-rgb: var(--ion-color-westerngrey-contrast-rgb);
    --ion-color-shade: var(--ion-color-westerngrey-shade);
    --ion-color-tint: var(--ion-color-westerngrey-tint);
}

@media (prefers-color-scheme: dark) {
    /*
    * Dark Colors
    * -------------------------------------------
    */
    body {
        --ion-color-primary: #0E375A ;
        --ion-color-primary-rgb: 169, 239, 1;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0, 0, 0;
        --ion-color-primary-shade: #3F77E2;
        --ion-color-primary-tint: #3F77E2;
        --ion-color-secondary: #ffffff;
        --ion-color-secondary-rgb: 255, 255, 255;
        --ion-color-secondary-contrast: #000000;
        --ion-color-secondary-contrast-rgb: 0, 0, 0;
        --ion-color-secondary-shade: #e0e0e0;
        --ion-color-secondary-tint: #ffffff;
        --ion-color-tertiary: rgba(248, 248, 248, 0.79);
        --ion-color-tertiary-rgb: 18, 18, 18;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #101010;
        --ion-color-tertiary-tint: #2a2a2a;
        --ion-background-color: #DCDCDC;
        --ion-background-color-rgb: 220, 220, 220;
        --ion-color-success: #2dd36f;
        --ion-color-success-rgb: 45, 211, 111;
        --ion-color-success-contrast: #ffffff;
        --ion-color-success-contrast-rgb: 255, 255, 255;
        --ion-color-success-shade: #28ba62;
        --ion-color-success-tint: #42d77d;
        --ion-color-warning: #ffc409;
        --ion-color-warning-rgb: 255, 196, 9;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0ac08;
        --ion-color-warning-tint: #ffca22;
        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235, 68, 90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;
        --ion-color-dark: #222428;
        --ion-color-dark-rgb: 34, 36, 40;
        --ion-color-dark-contrast: #ffffff;
        --ion-color-dark-contrast-rgb: 255, 255, 255;
        --ion-color-dark-shade: #1e2023;
        --ion-color-dark-tint: #383a3e;
        --ion-color-medium: #92949c;
        --ion-color-medium-rgb: 146, 148, 156;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #808289;
        --ion-color-medium-tint: #9d9fa6;
        --ion-color-light: #f4f5f8;
        --ion-color-light-rgb: 244, 245, 248;
        --ion-color-light-contrast: #000000;
        --ion-color-light-contrast-rgb: 0, 0, 0;
        --ion-color-light-shade: #d7d8da;
        --ion-color-light-tint: #f5f6f9;
        --ion-color-box: #f8f8f8;
    }
}

.header {
    background: #ffffff;
    width: 100%;
    height: 10%;
    position: absolute;
    top: 0%;
    z-index: 20;
}

.header-plat-sci {
    background: #231F20;
    width: 100%;
    height: 17%;
    position: absolute;
    top: 0%;
    z-index: 20;
}

.header-item-area {
    display: grid;
    grid-template-columns: 50% 50%;
}

.header-item-area-plat-sci {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 5%;
}

.header-menu {
    margin: auto;
    margin-left: 2%;
    width: 25%;
}

.load-header {
    padding: 35% 0% !important;
}

.LP-Icon-Start {
    width: 60%;
    margin: auto;
    /* animation: moveInTop 0.7s ease-in-out; */
}

.LP-Icon-Login {
    width: 40%;
    margin: auto;
}





@keyframes moveInLeft{
    0% {
        transform: translateX(-25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

@-webkit-keyframes moveInLeft{
    0% {
        transform: translateX(-25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

@keyframes moveInRight{
    0% {
        transform: translateX(25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

@-webkit-keyframes moveInRight{
    0% {
        transform: translateX(25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

@keyframes moveInTop{
    0% {
        transform: translateY(-25px);
        opacity: 0%;
    }
    100% {
        transform: translateY(0px);
        opacity: 100%;
    }
}

.login-card {
    background-color: white;
    height: 95%;
}

.login-card-forgor {
    background-color: white;
    height: auto;
}

ion-card-header.load-details-card-header {
    background-color: #0E375A !important;
}

.load-title-card {
    background: transparent !important;
    background-color: transparent !important;
}

ion-toolbar.load-toolbar {
    --background: #FFFFFF;
}

.sidebar-base {
    z-index: 5;
    position: absolute;
    height: calc(100%);
    animation: fadeInBlack 1s 1;
    background-color: rgba(109, 109, 109, 0.7);
}

.sidebar-menu-area {
    width: 100%;
    text-align: center;
    margin-top: 6vh;
    overflow-y: scroll;
}

.sidebar-menu-area-plat-sci {
    width: 100%;
    text-align: center;
    margin-top: 17vh;
    overflow-y: scroll;
}

.load-arrow-background {
    left: 0;
    width: 100%;
    color: #0E375A;
    position: absolute;
}

.loading-box {
    width: 85px;
    height: 85px;
    border-radius: 10px;
    background: white;
    text-align: center;
    margin: auto;
    margin-top: 75%;
    padding: 15px;
    box-shadow: 1px 1px 5px black;
}

@media only screen and (orientation: landscape) {
    .profile {
        display: none;
    }
    .home-plat-sci {
        margin-top: 20vh;
    }
}

@keyframes fadeInBlack {
    0% {
        background-color: transparent;
        background-color: rgba(201, 201, 201, 0.1);
    }
    50%,
    100% {
        width: 100vw;
        background-color: rgba(109, 109, 109, 0.7);
    }
}

.sidebar-menu {
    width: 80%;
    background-color: #ffffff;
    top: 10%;
    height: 93%;
    margin-bottom: 10%;
    overflow-y: scroll;
    animation: slideInRight 0.8s 1;
    text-align: center;
    flex-flow: row wrap;
}

@keyframes slideInRight {
    0% {
        width: 0vw;
    }
    50%,
    100% {
        width: 80vw;
    }
}

.news-tab-collapsed-plat-sci {
    top: 90% !important;
    width: 100%;
    position: fixed;
    transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
}

@media only screen and (height: 952px) and (orientation: portrait) {
    .home-div-plat-sci {
        text-align: center;
        margin-top: 4vh
        5vh !important;
        padding-bottom: 25%;
        flex-flow: row wrap;
        background: transparent !important;
    }
    .header-item-area-plat-sci {
        display: flex;
        margin-top: 7%;
    }
    .profile-header-plat-sci {
        margin-top: 5vh !important;
    }
    .header-title-plat-sci {
        width: 100%;
        padding-right: 10% !important;
        margin: auto;
        color: #f8f8f8;
    }
    .header-plat-sci {
        background: #231F20;
        width: 100%;
        height: 10vh;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .profile-plat-sci {
        padding-top: 15vh !important;
    }
    .home-plat-sci {
        padding-bottom: 0% !important;
    }
    .news-tab-collapsed-plat-sci {
        top: 90% !important;
        width: 100%;
        position: fixed;
        transition-property: all;
        transition-duration: 0.7s;
        transition-timing-function: ease-in-out;
    }
    .sidebar-menu-area-plat-sci {
        width: 100%;
        text-align: center;
        margin-top: 10vh !important;
        overflow-y: scroll;
    }
}

.home-avatar {
    position: relative;
    left: 3vw;
}

#edit-profile-icon {
    color: white;
    font-size: 6vw;
}

.sidebar-link {
    padding: 10px;
}

.sidebar-link:hover {
    border-right: 5px solid dimgray;
    background-color: gainsboro;
}

.profile-header-plat-sci {
    width: 100%;
    padding: 5px;
    margin-top: 17vh;
    /* position: fixed;
    bottom: 61vh;
    z-index: 3; */
    text-align: center;
}

.sidebar-btn {
    --border-width: 2px;
    --border-radius: 0px;
    width: 100%;
    height: 6vh;
    border-bottom: 1px solid black;
    margin: 1px 0px 0px 0px
}


.sidebar-btn-v {
    --border-width: 2px;
    --border-radius: 0px;
    width: 100%;
    height: 6vh;
    margin: 1px 0px 0px 0px
}

.sidebar-text:hover {
    --border-width: 2px;
    --border-radius: 0px;
    --color: #3F77E2;
}

#sidebar-text {
    color: black;
    font-weight: bolder;
    height: 100%;
    width: 100%;
    margin: 3vh 0vw 0vh 0vw;
    font-size: 4.2vw;
    text-align: start;
    font-family: Montserrat;
    font-weight: 600;
}

.menu-line {
    background: #ffffff;
    width: 30px;
    height: 4px;
    margin: 5px;
    border-radius: 4px;
}

.header-base {
    background: transparent;
    width: 100%;
    height: 6%;
}

.header-title {
    width: 100%;
    padding-right: 10%;
    color: #0E375A;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.header-title-plat-sci {
    width: 100%;
    padding-right: 10% !important;
    margin: auto;
    color: #0E375A;
}

.header-button-w {
    background: transparent;
    width: 10%
}

.navbar {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 94%;
}

.navbar-base {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    height: 6%;
}

.work-icon {
    height: 3.4vh;
}

.drive-icon {
    height: 3.4vh;
}

.help-icon {
    height: 3.4vh;
}

.inbox-icon {
    height: 3.4vh;
}

.home-icon-plat-sci {
    margin-top: 20%;
    height: 38px;
    width: 38px;
}

.home-icon {
    margin-top: 20%;
    height: 38px;
    width: 38px;
}

.navbar-work {
    margin: auto;
    font-size: 1.7rem;
    margin-top: 2.5%;
    color: var(--ion-color-secondary);
}

.navbar-money {
    margin: auto;
    font-size: 1.7rem;
    margin-top: 2.5%;
    color: var(--ion-color-secondary);
}

.navbar-inbox {
    margin: auto;
    font-size: 1.7rem;
    margin-top: 2.5%;
    color: var(--ion-color-secondary);
}

.navbar-help {
    margin: auto;
    font-size: 1.7rem;
    margin-top: 2.5%;
    color: var(--ion-color-secondary);
}

@media (min-width: 1100px) {
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 94%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 6%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .navbar-home-button {
        background-color: #99CC00;
        width: 4rem;
        height: 4rem;
        border: #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        font-size: 1.7rem;
        margin-top: -1.5rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    /* .ion-content#home {} */
    /* .ion-content#safety {
      --background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url()
    } */
    /* .ion-content#money {} */
    /* .ion-content#login {} */
    .ion-list#home {
        background: transparent !important;
        width: 65%;
        margin-left: 17%;
    }
    .ion-list#money {
        background: transparent !important;
        width: 65%;
        margin-left: 17%;
    }
    .ion-list#login {
        background: transparent !important;
        width: 65%;
        margin-left: 17%;
    }
    .ion-list#help {
        background: transparent !important;
        width: 65%;
        margin-left: 17%;
    }
    .ion-list#payroll {
        background: transparent !important;
        width: 65%;
        margin-left: 17%;
    }
    .ion-list#safety {
        background: transparent !important;
        width: 65%;
        margin-left: 17%;
    }
}


/* iPhone 5/SE */


/* iPhone 6/7/8 Plus */

@media only screen and (min-width: 320px) {
    #rewards-progress p {
        font-size: 10px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    .post-title h6 {
        font-size: 10px !important;
    }
    p.post-body {
        font-size: 1.5vh !important;
    }
    .profile-badge-card {
        max-height: 11vh !important;
    }
    .profile h2 {
        font-size: 16px !important;
    }
    .profile h3 {
        font-size: 14px !important;
    }
    .profile h6 {
        margin: 0 !important;
    }
    .profile p {
        font-size: 10px !important;
    }
    .more-info-button {
        font-size: 10px !important;
    }
    #apply {
        background: white !important;
        border-radius: 20px;
        margin-top: 10vh;
    }
    .referral-number {
        font-family: 'Montserrat', sans-serif;
        color: #3F77E2;
    }
    #referral span {
        font-family: 'Montserrat', sans-serif;
        color: #3F77E2;
    }
    #referral {
        height: 60vw !important;
        width: 60vw !important;
        border-radius: 50%;
        background-color: #fff;
        margin: 2vw;
    }
    #login-link {
        margin: 0 !important;
    }
    #login-header {
        padding: 0 !important;
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 92%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 8%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 10vh;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 30px;
        width: 30px;
    }
    .button-strong.ion-activated {
        --background: #DCDCDC !important;
    }
    ion-button.home-btn-left {
        --border-width: 2px !important;
        --border-radius: 10px !important;
        --background: #ffffff;
        width: 42vw !important;
        height: 20vh !important;
        margin: 10px 10px 5px 10px !important;
    }

    ion-button.home-btn-right {
        --border-width: 2px !important;
        --border-radius: 10px 0px 0px 10px !important;
        --background: #ffffff;
        width: 47.2vw !important;
        height: 20vh !important;
        margin: 10px 0px 5px 10px !important;
    }

    .navbar-home-button {
        background-color: #3F77E2;
        width: 3.8rem;
        height: 3.8rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -1.3rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.5rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.5rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.5rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.5rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* Pixel 2 XL */

@media only screen and (min-width: 360px) {
    #rewards-progress p {
        font-size: 12px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    .post-title h6 {
        font-size: 12px !important;
    }
    .profile-badge-card {
        max-height: 10vh !important;
    }
    .profile h2 {
        font-size: 18px !important;
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 38px;
        width: 38px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 26px !important;
        text-align: center;
        margin-top: -2.0rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* iPhone 6/7/8 */

@media only screen and (min-width: 375px) and (min-height: 667px) {
    #login-header {
        padding: 7% 0 !important;
    }
    #rewards-progress p {
        font-size: 12px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    /* .news {
        bottom: 6% !important;
    }
    .news-collapsed {
        top: 94% !important;
    }
    .news-header {
        bottom: 6% !important;
    } */
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .header-item-area {
        display: flex;
        margin-top: 2vh;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 92%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 8%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 9vh;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 25%;
        height: 32px;
        width: 32px;
    }
    .messageList {
        margin-top: 10vh !important;
        background: transparent !important;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.3rem;
        height: 4.3rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -1.6rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* iPhone 6/7/8 Plus */

@media only screen and (min-width: 414px) {
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 9%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 6%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 34px;
        width: 34px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.55rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -2.2rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* iPhone 12 and iPhone 12 Pro */

@media only screen and (min-width: 390px) {
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 94%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 6%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 38px;
        width: 38px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 5rem;
        height: 5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -2.2rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* iPhone 12 Mini, iPhone 11 Pro, iPhone Xs, and iPhone X */

@media only screen and (min-width: 375px) and (min-height: 812px) {
    #login-header {
        padding: 10% 0 !important;
    }
    #rewards-progress p {
        font-size: 10px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    /* .news {
        bottom: 6% !important;
    }
    .news-collapsed {
        top: 94% !important;
    }
    .news-header {
        bottom: 6% !important;
    } */
    #loginheader {
        padding-top: 30px !important;
    }
    .profile {
        width: 100%;
        height: 44vh;
        margin-top: 6vh;
        color: #f8f8f8;
        padding: 8vh;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .messageList {
        margin-top: 10vh !important;
        background: transparent !important;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .header-item-area {
        margin-top: 2vh;
        display: flex;
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 92%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 8%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 10vh;
    }
    /* .news-tab-collapsed {
        top: 90% !important;
    } */
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 25%;
        height: 32px;
        width: 32px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -1.8rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* Pixel 2 XL */

@media screen and (min-width: 411px) and (min-height: 823px) {
    /* .news-header {
        bottom: 6% !important;
    } */
    #rewards-progress p {
        font-size: 14px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    #login-header {
        padding: 12% 0 !important;
    }
    .news {
        top: 51% !important;
        bottom: 6% !important;
    }
    /* .news-header-expanded {
        bottom: 49% !important;
    } */
    .profile-badge-card {
        max-height: 75px !important;
    }
    .profile h2 {
        font-size: 24px !important;
    }
    .profile h3 {
        font-size: 18px !important;
    }
    .profile h6 {
        font-size: 16px !important;
    }
    .profile p {
        font-size: 12px !important;
    }
    .leaderboard-button p {
        margin: 0;
    }
}

@media screen and (min-width: 412px) and (min-height: 915px) {
    #login-header {
        padding: 12% 0 !important;
    }
    /* .news-header {
        bottom: 7% !important;
    } */
    #rewards-progress p {
        font-size: 14px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    /* .news {
        top: 51% !important;
        bottom: 7% !important;
    } */
    /* .news-header-expanded {
        bottom: 48% !important;
    } */
    .profile-badge-card {
        max-height: 75px !important;
    }
    .profile h2 {
        font-size: 24px !important;
    }
    .profile h3 {
        font-size: 18px !important;
    }
    .profile h6 {
        font-size: 16px !important;
    }
    .profile p {
        font-size: 12px !important;
    }
    .leaderboard-button p {
        margin: 0;
    }
    #rewards-progress p {
        font-size: 14px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .header-item-area {
        margin-top: 0.5vh;
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 6vh;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 38px;
        width: 38px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -1.8rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}

@media screen and (min-width: 414px) and (min-height: 896px) {
    #login-header {
        padding: 12% 0 !important;
    }
    /* .news-header {
        bottom: 7% !important;
    } */
    #rewards-progress p {
        font-size: 14px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    /* .news {
        top: 51% !important;
        bottom: 7% !important;
    } */
    /* .news-header-expanded {
        bottom: 48% !important;
    } */
    .profile-badge-card {
        max-height: 75px !important;
    }
    .profile h2 {
        font-size: 24px !important;
    }
    .profile h3 {
        font-size: 18px !important;
    }
    .profile h6 {
        font-size: 16px !important;
    }
    .profile p {
        font-size: 12px !important;
    }
    .leaderboard-button p {
        margin: 0;
    }
    #rewards-progress p {
        font-size: 14px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .header-item-area {
        margin-top: 3vh;
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 6vh;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 38px;
        width: 38px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -1.8rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}

@media only screen and (min-width: 411px) and (min-height: 731px) {
    #login-header {
        padding: 0% 0 !important;
    }
    /* .news-header {
        bottom: 7% !important;
    } */
    #rewards-progress p {
        font-size: 14px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    /* .news {
        top: 51% !important;
        bottom: 7% !important;
    } */
    /* .news-header-expanded {
        bottom: 48% !important;
    } */
    .profile-badge-card {
        max-height: 75px !important;
    }
    .profile h2 {
        font-size: 24px !important;
    }
    .profile h3 {
        font-size: 18px !important;
    }
    .profile h6 {
        font-size: 16px !important;
    }
    .profile p {
        font-size: 12px !important;
    }
    .leaderboard-button p {
        margin: 0;
    }
    #rewards-progress p {
        font-size: 14px !important;
        /* height: 22vw !important;
        width: 22vw !important; */
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 6%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 6vh;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 38px;
        width: 38px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -1.8rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* iPhone 11 Pro Max */

@media only screen and (min-height: 812px) {
    #login-header {
        padding: 12% 0 !important;
    }
    .profile h2 {
        font-size: 24px !important;
    }
    .profile h3 {
        font-size: 18px !important;
    }
    .profile p {
        font-size: 12px !important;
    }
    .profile-badge-card {
        max-height: 8.5vh !important;
    }
    /* .news-header-expanded {
        bottom: 49% !important;
    }
    .news {
        top: 51% !important;
    } */
    .leaderboard-button p {
        margin: 0 !important;
    }
}

@media only screen and (min-width: 414px) {
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 38px;
        width: 38px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.55rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -1.8rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}


/* iPhone 12 Pro Max */

@media only screen and (min-width: 428px) {
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 92%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 8%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 9vh;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 38px;
        width: 38px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 5rem;
        height: 5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        font-size: 30px !important;
        text-align: center;
        margin-top: -2.2rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 1.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}

@media only screen and (min-width: 720px) and (orientation: portrait) {
    /* .profile {
        height: 4vh !important;
    } */
    .news-tab {
        top: 10% !important;
    }
    .news-tab-collapsed {
        top: 88% !important;
    }
    #rewards-progress p {
        font-size: 20px !important;
    }
    ion-label#profile {
        font-size: 3vw !important;
    }
    .leaderboard-button {
        display: block;
        font-size: 16px !important;
        height: 12vw !important;
        width: 12vw !important;
    }
    .more-info-button {
        font-size: 16px !important;
        height: 36px !important;
    }
    .leaderboard-button p {
        margin: 4px;
    }
    .profile p {
        font-size: 16px !important;
    }
    .profile h3 {
        font-size: 20px !important;
    }
    #badge-box.profile-badge-card {
        max-height: 12vh !important;
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .menu-icon {
        position: absolute !important;
        width: 15vw !important;
        margin-top: 2vh !important;
    }
    .menu-item-icon {
        width: 6vw !important;
        margin-right: 1vh !important;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 92%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 8%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 48px;
        width: 48px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 6.5rem;
        height: 6.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -2.5rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 2.4rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 2.4rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 2.4rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 2.4rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}

@media only screen and (min-height: 1920px) {
    .sc-ion-modal-md-h {
        --width: 90%;
        --height: 80%;
    }
    #big-number {
        font-size: 10vw;
    }
    #edit-profile-icon {
        font-size: 4vw;
    }
    ion-button.new_message {
        --border-width: 2px !important;
        --border-radius: 50px !important;
        top: calc(86%) !important;
        position: absolute !important;
        width: 90px !important;
        height: 90px !important;
        margin-left: calc(85%) !important;
    }
    .news-card {
        padding: 1vh !important;
    }
    .news-tab-collapsed {
        top: 90%
    }
    .header-item-area {
        margin-top: 1vh;
    }
    .header-title h4 {
        font-size: 48px;
    }
    .work-icon {
        width: 6vw;
        height: 6vw;
    }
    .navbar-work ion-icon {
        font-size: 6vw;
    }
    .navbar-inbox ion-icon {
        font-size: 6vw;
    }
    .navbar-help ion-icon {
        font-size: 6vw;
    }
    .navbar-home-button {
        height: 10rem !important;
        width: 10rem !important;
    }
    img.home-icon {
        height: 6vw;
        width: 6vw;
    }
    .menu-line {
        width: 60px !important;
        height: 7px !important;
        margin: 10px !important;
        border-radius: 4px;
    }
    .menu-icon {
        left: 32px !important;
    }
    #btn-text-menu {
        padding-left: 32px !important;
    }
    img#profile-avatar {
        max-width: 40vw !important;
        max-height: 40vw !important;
    }
    .profile h2 {
        font-size: 3vh !important;
    }
    .profile h3 {
        font-size: 2vh !important;
    }
    .post-title h6,
    p {
        font-size: 1.5vh !important;
    }
    p#post-date {
        font-size: 1.5vh !important;
    }
    p.post-body {
        font-size: 1.5vh !important;
    }
    p.post-body-expanded {
        font-size: 1.5vh !important;
    }
}

@media only screen and (max-width: 280px) {
    .news {
        bottom: 6vh !important;
    }
    .post-title h6 {
        font-size: 10px !important;
        margin: 0 !important;
    }
    .profile h2 {
        font-size: 18px !important;
    }
    .profile-badge-card {
        max-height: 8vh !important;
    }
    .leaderboard-button p {
        font-size: 10px !important;
        margin: 0 !important;
    }
    .more-info-button {
        font-size: 10px !important;
    }
    .profile h3 {
        font-size: 14px !important;
        margin: 0 !important;
    }
    .profile h6 {
        font-size: 10px !important;
    }
    .profile p {
        font-size: 10px !important;
    }
}

@media only screen and (min-width: 540px) {
    .profile {
        height: 45vh !important;
    }
    /* img#profile-avatar {
        height: 25vw !important;
        width: 25vw!important;
    } */
    .profile-badge-card {
        max-height: 13vh !important;
    }
    .leaderboard-button {
        height: 12vw !important;
        width: 12vw !important;
    }
    .leaderboard-button p {
        margin: 0;
    }
    /* .news-tab {
        top: 50% !important;
    } */
}

@media only screen and (min-width: 800px) {
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 20%;
        height: 52px;
        width: 52px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 8rem;
        height: 8rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -3.0rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}

@media only screen and (min-width: 1024px) {
    .news-tab {
        top: 10% !important;
    }
    .news-tab-collapsed {
        top: 88% !important;
    }
    .news-header h4 {
        font-size: 4vw !important;
    }
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    #rewards-progress p {
        font-size: 26px !important;
    }
    .news-tab {
        top: 17% !important;
    }
    .news-tab-collapsed {
        top: 85% !important;
    }
    .news-header h4 {
        font-size: 2vw !important;
    }
    .profile h2 {
        font-size: 36px !important;
    }
    .profile p {
        font-size: 26px !important;
    }
    .profile h3 {
        font-size: 32px !important;
    }
    .profile h6 {
        font-size: 24px !important;
    }
    .profile {
        height: 47vh !important;
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    #btn-text-menu {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 3vh 0vw 0vh 0vw !important;
        font-size: 3.8vw;
        text-align: left;
        font-family: Montserrat;
        font-weight: 600;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .menu-icon {
        position: absolute;
        width: 15vw;
        margin-top: 2vh;
    }
    .menu-item-icon {
        width: 4vw !important;
        margin-right: 1vh;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }
    /* .navbar-base:before {
        content: '';
        position: absolute;
        left: 39%;
        bottom: 95%;
        width: 18%;
        height: 9%;
        border-radius: 100%;
        box-shadow: 0px 76px 0px 50px #231F20;
    }

    .navbar-base-bar-1 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    }

    .navbar-base-bar-2 {
        background-color:#231f20;
        width: 39%;
        height: 10%;
        display: flex;
    } */
    .home-icon {
        margin-top: 25%;
        height: 52px;
        width: 52px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 8rem;
        height: 8rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -3.0rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 2.5%;
        color: var(--ion-color-secondary);
    }
}

/* @media only screen and (min-width: 1025px) {
    .profile-header h4 {
        font-size: 48px !important;
    }
    .profile-card h4 {
        font-size: 48px !important;
    }
    ion-label#profile {
        font-size: 32px !important;
    }
    #dm-quote {
        font-size: 3vh !important;
    }
    .post-title h6,
    p {
        font-size: 1.5vh !important;
    }
    p.post-body {
        font-size: 1.5vh !important;
    }
    .news-tab-collapsed {
        top: 85%;
    }
    .news-header ion-icon {
        font-size: 3vh !important;
    }
    /* .navbar-home-button-plat-sci {
        width: 9rem !important;
        height: 9rem !important;
    }
    img.home-icon {
        height: 5rem;
        width: 5rem;
    } */
    /* .navbar-home-button {
        background-color: #99CC00;
        width: 16rem !important;
        height: 16rem !important;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -4.2rem;
    } */
    /* #edit-profile-icon {
        font-size: 35px;
    }
    #personal-info-icon {
        font-size: 25px !important;
    }

    #rewards-progress p {
        font-size: 26px !important;
    }
    .profile h2 {
        font-size: 36px !important;
    }
    .profile p {
        font-size: 26px !important;
    }
    .profile h3 {
        font-size: 32px !important;
    }
    .profile h6 {
        font-size: 24px !important;
    }
    .profile {
        height: 47vh !important;
    }
    .news-header {
        bottom: 7% !important;
    }
    .news-header-expanded {
        bottom: 51% !important;
    }
    .news {
        top: 49% !important;
        bottom: 7% !important;
    }
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 6%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .menu-icon {
        position: absolute;
        width: 15vw;
        margin-top: 2vh
    }
    .menu-icon img {
        max-width: 60%;
    }

    .sidebar-btn {
        --border-width: 2px;
        --border-radius: 0px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid black;
        margin: 1px 0px 0px 0px
    }

    #sidebar-text {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 2vh 0vw 2vh 0vw;
        font-size: 2vw;
        text-align: start;
        font-family: Montserrat;
        font-weight: 600;
    }

    #btn-text-menu {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 3vh 0vw 0vh 0vw !important;
        font-size: 3.8vw;
        text-align: left;
        font-family: Montserrat;
        font-weight: 600;
    }
    ion-button.home-btn {
        --border-width: 2px !important;
        --border-radius: 10px !important;
        --background: #f8f8f8;
        width: 42vw !important;
        height: 42vh !important;
        margin: 10px 10px 5px 10px !important;
    }
    ion-button.home-menu-item {
        --border-width: 1px;
        --border-radius: 0px;
        border-bottom: 1px solid black;
        width: 100vw;
        height: auto !important;
        margin: 0px 10px 0px 0px
    }
    .menu-item-icon {
        width: 6vw;
        margin-right: 1vh;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 93%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 7%;
    }

    .home-icon {
        margin-top: 25%;
        height: 52px;
        width: 52px;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 8rem;
        height: 8rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -4.2rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
} */

@media screen and (max-width: 900px) and (orientation: landscape) {
    #edit-profile-icon {
        font-size: 35px;
    }
    #personal-info-icon {
        font-size: 25px !important;
    }
    .home-div {
        text-align: center;
        margin-top: 4vh !important;
        padding-bottom: 25%;
        flex-flow: row wrap;
        background: transparent !important;
    }
    .news-tab-collapsed {
        top: 75% !important;
    }
    .news-tab {
        top: 10% !important
    }
    #rewards-progress p {
        font-size: 26px !important;
    }
    .profile h2 {
        font-size: 36px !important;
    }
    .profile p {
        font-size: 26px !important;
    }
    .profile h3 {
        font-size: 32px !important;
    }
    .profile h6 {
        font-size: 24px !important;
    }
    .profile {
        display: none;
    }
    .profile-header {
        width: 100%;
        padding: 5px;
        margin-top: 12vh;
        /* position: fixed;
        bottom: 61vh;
        z-index: 3; */
        text-align: center;
    }
    .profile-main {
        background-color: #dcdcdc;
        height: auto !important;
    }
    /* .news-header {
        bottom: 15% !important;
    }
    .news-header-expanded {
        bottom: 51% !important;
    }
    .news {
        top: 49% !important;
        bottom: 7% !important;
    } */
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 12%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .header-item-area {
        display: flex;
        margin-top: 0vh;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .menu-icon {
        position: absolute;
        width: 15vw;
        margin-top: 2vh
    }
    .menu-icon img {
        max-width: 60%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 12vh;
    }
    .sidebar-menu {
        width: 80%;
        background-color: #ffffff;
        top: 10%;
        height: 85%;
        margin-bottom: 10%;
        overflow-y: scroll;
        animation: slideInRight 0.8s 1;
        text-align: center;
        flex-flow: row wrap;
    }
    .sidebar-btn {
        --border-width: 2px;
        --border-radius: 0px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid black;
        margin: 1px 0px 0px 0px
    }
    #sidebar-text {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 2vh 0vw 2vh 0vw;
        font-size: 2vw;
        text-align: start;
        font-family: Montserrat;
        font-weight: 600;
    }
    #btn-text-menu {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 3vh 0vw 0vh 0vw !important;
        font-size: 3.8vw;
        text-align: left;
        font-family: Montserrat;
        font-weight: 600;
    }
    ion-button.home-btn {
        --border-width: 2px !important;
        --border-radius: 10px !important;
        --background: #f8f8f8;
        width: 42vw !important;
        height: 42vh !important;
        margin: 10px 10px 5px 10px !important;
    }
    ion-button.home-menu-item {
        --border-width: 1px;
        --border-radius: 0px;
        border-bottom: 1px solid black;
        width: 100vw;
        height: auto !important;
        margin: 0px 10px 0px 0px
    }
    .menu-item-icon {
        width: 6vw !important;
        margin-right: 1vh;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 87%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 13%;
    }
    .home-icon {
        margin-top: 20%;
        height: 35px;
        width: 35px;
    }
    .drive-icon {
        height: 8vh;
    }
    .help-icon {
        height: 8vh;
    }
    .inbox-icon {
        height: 8vh;
    }
    .work-icon {
        height: 8vh;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -2rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 2.7rem;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 2.7rem;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 2.7rem;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 2.7rem;
        color: var(--ion-color-secondary);
    }
}

@media screen and (min-width: 900px) and (orientation: landscape) {
    #edit-profile-icon {
        font-size: 35px;
    }
    #personal-info-icon {
        font-size: 25px !important;
    }
    .news-tab-collapsed {
        top: 82% !important;
    }
    .home-div {
        text-align: center;
        margin-top: 4vh;
        padding-bottom: 25%;
        flex-flow: row wrap;
        background: transparent !important;
    }
    .news-tab-collapsed {
        top: 86% !important;
    }
    /* .news-tab {
        top: 88% !important;
    } */
    #rewards-progress p {
        font-size: 26px !important;
    }
    .profile h2 {
        font-size: 36px !important;
    }
    .profile p {
        font-size: 26px !important;
    }
    .profile h3 {
        font-size: 32px !important;
    }
    .profile h6 {
        font-size: 24px !important;
    }
    .profile {
        display: none;
    }
    .profile-header {
        width: 100%;
        padding: 5px;
        margin-top: 7vh;
        /* position: fixed;
        bottom: 61vh;
        z-index: 3; */
        text-align: center;
    }
    .profile-main {
        background-color: #dcdcdc;
        height: auto !important;
    }
    /* .news-header {
        bottom: 15% !important;
    }
    .news-header-expanded {
        bottom: 51% !important;
    }
    .news {
        top: 49% !important;
        bottom: 7% !important;
    } */
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .header-item-area {
        display: flex;
        margin-top: 0vh;
        align-items: center;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .menu-icon {
        position: absolute;
        width: 15vw;
        margin-top: 2vh
    }
    .menu-icon img {
        max-width: 60%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 12vh;
    }
    .sidebar-menu {
        width: 80%;
        background-color: #ffffff;
        top: 10%;
        height: 90%;
        margin-bottom: 10%;
        overflow-y: scroll;
        animation: slideInRight 0.8s 1;
        text-align: center;
        flex-flow: row wrap;
    }
    .sidebar-btn {
        --border-width: 2px;
        --border-radius: 0px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid black;
        margin: 1px 0px 0px 0px
    }
    #sidebar-text {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 2vh 0vw 2vh 0vw;
        font-size: 2vw;
        text-align: start;
        font-family: Montserrat;
        font-weight: 600;
    }
    #btn-text-menu {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 3vh 0vw 0vh 0vw !important;
        font-size: 3.8vw;
        text-align: left;
        font-family: Montserrat;
        font-weight: 600;
    }
    ion-button.home-btn {
        --border-width: 2px !important;
        --border-radius: 10px !important;
        --background: #f8f8f8;
        width: 42vw !important;
        height: 42vh !important;
        margin: 10px 10px 5px 10px !important;
    }
    ion-button.home-menu-item {
        --border-width: 1px;
        --border-radius: 0px;
        border-bottom: 1px solid black;
        width: 100vw;
        height: auto !important;
        margin: 0px 10px 0px 0px
    }
    .menu-item-icon {
        width: 6vw;
        margin-right: 1vh;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 90%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 10%;
    }
    .home-icon {
        margin-top: 25%;
        height: 40px;
        width: 40px;
    }
    .drive-icon {
        height: 6vh;
    }
    .help-icon {
        height: 6vh;
    }
    .inbox-icon {
        height: 6vh;
    }
    .work-icon {
        height: 6vh;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 6.2rem;
        height: 6.2rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -1.8rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 1%;
        color: var(--ion-color-secondary);
    }
}

@media screen and (max-width: 600px) and (orientation: landscape) {
    #edit-profile-icon {
        font-size: 35px;
    }
    #personal-info-icon {
        font-size: 25px !important;
    }
    .home-div {
        text-align: center;
        margin-top: 4vh;
        padding-bottom: 25%;
        flex-flow: row wrap;
        background: transparent !important;
    }
    #rewards-progress p {
        font-size: 26px !important;
    }
    .profile h2 {
        font-size: 36px !important;
    }
    .profile p {
        font-size: 26px !important;
    }
    .profile h3 {
        font-size: 32px !important;
    }
    .profile h6 {
        font-size: 24px !important;
    }
    .profile {
        display: none;
    }
    .profile-header {
        width: 100%;
        padding: 5px;
        margin-top: 12vh;
        /* position: fixed;
        bottom: 61vh;
        z-index: 3; */
        text-align: center;
    }
    .profile-main {
        background-color: #dcdcdc;
        height: auto !important;
    }
    /* .news-header {
        bottom: 15% !important;
    }
    .news-header-expanded {
        bottom: 51% !important;
    }
    .news {
        top: 49% !important;
        bottom: 7% !important;
    } */
    #loginheader {
        padding-top: 30px !important;
    }
    #modalheader {
        height: 70px !important;
        padding-top: 30px !important;
    }
    #breakdownheader {
        height: 100px !important;
        padding-top: 30px !important;
    }
    .header {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 0%;
        z-index: 20;
    }
    .header-item-area {
        display: flex;
        margin-top: 0vh;
    }
    .menu-line {
        background: #ffffff;
        width: 30px;
        height: 4px;
        margin: 5px;
        border-radius: 4px;
    }
    .menu-icon {
        position: absolute;
        width: 15vw;
        margin-top: 2vh
    }
    .menu-icon img {
        max-width: 60%;
    }
    .sidebar-menu-area {
        width: 100%;
        text-align: center;
        margin-top: 12vh;
    }
    .sidebar-menu {
        width: 80%;
        background-color: #ffffff;
        top: 10%;
        height: 85%;
        margin-bottom: 10%;
        overflow-y: scroll;
        animation: slideInRight 0.8s 1;
        text-align: center;
        flex-flow: row wrap;
    }
    .sidebar-btn {
        --border-width: 2px;
        --border-radius: 0px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid black;
        margin: 1px 0px 0px 0px
    }
    #sidebar-text {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 2vh 0vw 2vh 0vw;
        font-size: 2vw;
        text-align: start;
        font-family: Montserrat;
        font-weight: 600;
    }
    #btn-text-menu {
        color: black;
        font-weight: bolder;
        height: 100%;
        width: 100%;
        margin: 3vh 0vw 0vh 0vw !important;
        font-size: 3.8vw;
        text-align: left;
        font-family: Montserrat;
        font-weight: 600;
    }
    ion-button.home-btn {
        --border-width: 2px !important;
        --border-radius: 10px !important;
        --background: #f8f8f8;
        width: 42vw !important;
        height: 42vh !important;
        margin: 10px 10px 5px 10px !important;
    }
    ion-button.home-menu-item {
        --border-width: 1px;
        --border-radius: 0px;
        border-bottom: 1px solid black;
        width: 100vw;
        height: auto !important;
        margin: 0px 10px 0px 0px
    }
    .menu-item-icon {
        width: 6vw;
        margin-right: 1vh;
    }
    .header-base {
        background: transparent;
        width: 100%;
        height: 6%;
    }
    .header-button-w {
        background: transparent;
        width: 10%
    }
    .navbar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 85%;
    }
    .navbar-base {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        height: 15%;
    }
    .home-icon {
        margin-top: 20%;
        height: 35px;
        width: 35px;
    }
    .drive-icon {
        height: 8vh;
    }
    .help-icon {
        height: 8vh;
    }
    .inbox-icon {
        height: 8vh;
    }
    .work-icon {
        height: 8vh;
    }
    .navbar-home-button {
        background-color: #99CC00;
        width: 4.5rem;
        height: 4.5rem;
        border: 3.5px solid #ffffff;
        border-radius: 50rem;
        margin: auto;
        text-align: center;
        margin-top: -1.8rem;
    }
    .navbar-work {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 0%;
        color: var(--ion-color-secondary);
    }
    .navbar-money {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 0%;
        color: var(--ion-color-secondary);
    }
    .navbar-inbox {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 0%;
        color: var(--ion-color-secondary);
    }
    .navbar-help {
        margin: auto;
        font-size: 2.7rem;
        margin-top: 0%;
        color: var(--ion-color-secondary);
    }
}

ion-item#login {
    --background: transparent !important
}

ion-list#login {
    background: transparent !important;
}

ion-toolbar#login {
    --background: transparent
}

#loginheader {
    padding-top: 5px;
}

#modalheader {
    padding-top: 0px;
}

#breakdownheader {
    padding: 0px;
}

.refresher {
    background-color: transparent !important;
}

.messageList {
    margin-top: 8vh;
    background: transparent !important;
}

.message-box {
    background: #f8f8f8;
    display: flex;
    margin: 2vh 10px 2vh 10px
}

.message-icon {
    margin: 3vh 2vw 0vh 2vw;
    color: black;
    width: auto;
}

.message-content {
    color: black;
    margin: 1vh 0vw 1vh 0vw;
}

.message-body {
    color: black;
}

#messageList {
    background: #DCDCDC;
}

ion-list#inbox {
    background: transparent !important;
}

ion-button.login-btn {
    --border-width: 2px;
    --border-radius: 20px;
}

ion-button.home {
    --border-width: 2px;
    --border-radius: 20px;
    padding: 5px 10vw 5px 10vw;
}

ion-button.home-bt {
    --border-width: 2px;
    --border-radius: 10px;
    width: 44vw;
    height: 20vh;
    margin: 10px 10px 5px 10px
}

ion-button.home-bt:active {
    --border-width: 2px;
    --border-radius: 10px;
    width: 44vw;
    height: 20vh;
    margin: 10px 10px 5px 10px
}

ion-select,
ion-datetime {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    outline: 1px solid #666666;
}

ion-button {
    text-transform: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 8px;
    font-size: 18px;
}

ion-grid h6 {
    font-family: "Montserrat", sans-serif;
    margin: 8px;
    font-size: 12px;
}

ion-button#western-button {
    --ion-color-primary: #0E375A;
    background-color: #0E375A;
    border-radius: 10px;
    color: #ffffff;
}

ion-button#payroll {
    --ion-color-primary: rgba(248, 248, 248, 0.65);
    color: #000000;
}

ion-button {
    --box-shadow: none;
    /* --padding-start: 0;
    --padding-end: 0; */
}

ion-textarea#western {
    width: calc(100% - 30px);
    height: 90px;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f8f8f8;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin: 8px;
    font-size: 12px;
}

#referral {
    height: 60vw;
    width: 60vw;
    border-radius: 50%;
    background-color: #fff;
    margin: 2vw;
}

.referral-number {
    font-family: 'Montserrat', sans-serif;
    color: #3F77E2;
}

.orientation-roadtest {
    background-color: #fff;
}

.orientation-roadtest p,
li {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin: 8px;
    font-size: 14px;
}

.orientation-roadtest strong {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 14px;
}

ion-button.home-menu-item {
    --border-width: 1px;
    border-radius: 0px;
    border-bottom: 1px solid black;
    width: 100vw;
    height: 7vh;
    margin: 0px 10px 0px 0px
}

ion-button.X {
    --border-width: 2px;
    --border-radius: 20px;
    position: absolute;
    height: 40px;
    margin-left: 28px;
}

#new-message-box {
    --padding-start: 0;
    width: 100%;
    top: calc(85%);
    color: black;
    --background: transparent;
}

#message-label {
    width: 85%;
    /* float: direction; */
    --background: transparent;
}

ion-button.new_message {
    --border-width: 2px;
    --border-radius: 30px;
    top: calc(82%);
    position: absolute;
    width: 60px;
    height: 60px;
    margin-left: calc(80%);
}

ion-button.back {
    --border-width: 2px;
    --border-radius: 20px;
    padding: 5px 55vw 5px 5vw;
}

new-message {
    --border-width: 2px;
    --border-radius: 20px;
    position: absolute !important;
    height: 40px;
    margin-left: 28px;
}

ion-button {
    --border-radius: 20px;
}

.menu-button {
    text-align: center;
    flex-flow: row wrap;
}

.menu-icon {
    position: absolute;
    width: 17vw;
    margin-top: 2vh;
    left: 0;
}

.menu-item-icon {
    width: 8vw;
    margin-right: 1vh;
}

#loginbtn1 {
    margin-top: 12px;
}

#btn-text {
    color: white;
    font-weight: bolder;
}

#btn-text-header {
    color: #f8f8f8;
    font-weight: bolder;
    height: 100%;
    width: 100%;
    font-size: 4.2vw;
    text-align: start;
    font-family: Montserrat;
    font-weight: 600;
}

#btn-text-header span {
    display: block;
    background-color: #231F20;
    width: 100%;
    padding: 8px;
}

#btn-text-menu {
    color: black;
    font-weight: bolder;
    height: 100%;
    width: 150%;
    margin: 3vh 0vw 0vh 0vw !important;
    font-size: 3.8vw;
    text-align: left;
    font-family: Montserrat;
    font-weight: 600;
}

#btn-text-submenu {
    color: black;
    font-weight: bolder;
    height: 100%;
    width: 100%;
    margin: 3vh 0vw 0vh 0vw;
    font-size: 3.8vw;
    text-align: left;
    font-family: Montserrat;
    font-weight: 600;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

.home-div {
    text-align: center;
    margin-top: 4vh;
    padding-bottom: 25%;
    flex-flow: row wrap;
    background: transparent !important;
}

.home-div-plat-sci {
    text-align: center;
    margin-top: 6vh;
    padding-bottom: 25%;
    flex-flow: row wrap;
    background: transparent !important;
}

.home {
    text-align: center;
    padding-bottom: 25%;
    flex-flow: row wrap;
    background: transparent !important;
}

.home-button {
    --border-width: 2px;
    --border-radius: 10px;
    width: 44vw;
    height: 12vh;
    margin: 10px 10px 5px 10px
}


/* ion-content#home {
    display: flex;
} */

ion-toolbar#home {
    --background: transparent;
    --border-width: 0 0 0 0;
}


/* ion-content#wsxi {} */

ion-content#home {
    --background: #f4f5f8;
}

ion-list#money {
    background: transparent !important;
}

ion-toolbar#money {
    --background: transparent;
    --border-width: 0 0 0 0;
}


/* ion-content#money {} */

ion-list#help {
    background: transparent !important;
}

ion-toolbar#help {
    --background: transparent;
    --border-width: 0 0 0 0;
}


/* ion-content#help {} */

ion-list#safety {
    background: transparent !important;
}

ion-toolbar#safety {
    --background: transparent;
    --border-width: 0 0 0 0;
}

ion-card#no-planned-loads {
    background-color: #6e6e6e;
}

ion-content#inbox {
    --background: #DCDCDC;
    margin-top: 10vh;
}

ion-list#payroll {
    background: transparent !important;
}

ion-toolbar#payroll {
    --background: #FFFFFF;
    --border-width: 0 0 0 0;
}

#payroll-faq {
    --offset-bottom: -60px !important;
}


/* ion-content#payroll {} */

#submit-button {
    margin-bottom: 10vh;
}

#test {
    padding-top: 6%;
    padding-left: 20%;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#help-desk-list {
    margin-bottom: 10vh;
}

ion-note {
    font-size: 12pt;
}

.list-md {
    background: transparent !important;
}

.list-ios {
    background: transparent !important;
}

/* ion-label {
    --ion-font-family: 'Montserrat' sans-serif;
    font-weight: 600;
} */

h2.question {
    font-size: 20px;
    margin-bottom: 10px;
}

ion-toggle {
    margin-right: 10px;
}

.profile h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin: 8px;
    font-size: 24px;
}

.profile h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 8px;
    font-size: 18px;
}

.edit-profile-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 3vw;
    text-align: center;
    color: white;
    border-radius: 5px;
    width: 70%;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    height: auto;
    padding: 4px;
    margin-right: 3vw;
    text-decoration: none;
}
.load-profile-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 3vw;
    text-align: center;
    color: white;
    border-radius: 5px;
    width: 30%;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    height: auto;
    padding: 4px;
    text-decoration: none;
}
.current-load-profile {
    width: 70%;
}
.more-profile-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 3vw;
    color: grey;
    border-radius: 5px;
    width: 30%;
    background-color: #fff;
    border: 1px solid white;
    height: auto;
    padding: auto;
}

.profile-header h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 0;
    font-size: 4vw;
}

.profile h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin: 8px;
    font-size: 3.5vw !important;
}

.profile p {
    font-family: "Montserrat";
    margin: 0;
    font-weight: 500;
    font-size: 4vw !important
}

.profile-main select {
    color: black;
    font-size: 4vw !important;
    background-color:  rgba(0,0,0,0);
    outline: None;
    border: None;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 0;
    display: inline-flex;
}

.more-info-button {
    background-color: #666666;
    border-radius: 12px;
    font-family: "Montserrat", sans-serif;
    padding: 8px;
    font-weight: 400;
    font-size: 12px;
    height: 28px;
    color: #f8f8f8;
}

.profile-header {
    width: 100%;
    padding: 5px;
    /* position: fixed;
    bottom: 61vh;
    z-index: 3; */
    text-align: center;
}

.dark {
    background-color: #0E375A;
    color: #f8f8f8;
}

.orange {
    background-color: #ed8254;
}

.dark-grey {
    background-color: #666666;
    color: #f8f8f8;
}

.light {
    color: #0E375A;
    background-color: #fff;
}

.profile-card-grid {
    display: grid;
    grid-template-columns: 60% 35%;
    grid-gap: 5%;
    margin-bottom: 16px;
}

.underline {
    text-decoration: underline;
}

#icon-text {
    display: inline-flex;
    align-items: center;
}

#dm-quote {
    font-family: "Montserrat";
    margin: auto;
    padding-bottom: 10px;
    width: 65%;
    font-weight: 500;
    font-size: 3vw;
}

.badges {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 8px;
}

.badges-ex {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 8px;
}

#show-less {
    color: #666666;
    width: 100%;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 12px;
    margin-top: 4px;
}

#u1-tenure-badge {
    width: 15vw;
    height: 15vw;
    text-align: center;
    color: #b08d57;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 2px #666666;
}

#tenure-badge {
    width: 15vw;
    height: 15vw;
    text-align: center;
    color: #fff;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 2px #666666;
}

.leaderboard-button {
    height: 15vw;
    width: 15vw;
    border-radius: 5px;
    background-color: #231F20;
    color: #fff;
    font-family: "Montserrat";
    text-align: center;
    font-weight: 500;
    font-size: 12px;
}

.leaderboard-button img {
    width: 80%;
    margin-top: 3px;
}

.leaderboard-button p {
    margin: 0;
}

.meet-your-dm {
    display: inline-flex;
    column-gap: 8vw;
    align-items: center;
}

.dm-info div:nth-child(n) {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
}

.meet-your-dm img {
    height: 25vw;
    width: 25vw;
    max-width: 200px;
    max-height: 200px;
    /* object-fit: cover; */
    /* border-radius: 50%  */
}

.profile-card {
    text-align: center;
    height: auto;
    width: auto;
    color: black;
    border-radius: 10px;
    background-color: rgba(248, 248, 248, 0.65);
}

ion-label#light {
    color: #fff;
}

#news-modal {
    width: 90%;
    height: 75%;
}

.news-card {
    display: grid;
    grid-template-columns: 70% 30%;
    text-align: left;
    height: auto;
    width: auto;
    border-radius: 10px;
    background-color: #f8f8f8;
    padding: 8px;
    margin-bottom: 1.5vh;
    color: #231F20;
}

.post-title h6 {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 4px;
}

.news {
    overflow-y: auto;
}

.news-main {
    background-color: #dcdcdc;
    padding: 16px;
    height: 90vh;
    margin-bottom: 10vh;
    overflow-y: auto;
}

.post-title {
    display: inline-flex;
    align-items: baseline;
}

.post-title p {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    padding: 0;
    margin: 0;
    font-size: 10px;
}

#post-date {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    padding: 4px;
    margin: 0;
    font-size: 12px;
}

.post-body {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 4px;
    margin: 0;
    font-size: 1.5vh;
    color: #666666;
    max-height: 65px;
}

.post-body-expanded {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 4px;
    margin: 0;
    font-size: 1.5vh;
    color: #666666;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
    height: auto;
    max-height: 600px;
}


/* .news-card.img {

} */

#big-number {
    font-family: "Lato";
    font-weight: 900;
    font-size: 10vw;
}

.profile-card h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
    padding: 8px 0 0 0;
    margin: 0;
    font-size: 4vw;
}

.profile-badge-card {
    display: flex;
    column-gap: 8px;
    max-height: 9vh;
    width: auto;
    border-radius: 10px;
    background-color: rgba(248, 248, 248, 0.65);
    padding: 8px;
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
}

.profile-badge-card-ex {
    column-gap: 8px;
    /* display: flex; */
    width: auto;
    border-radius: 10px;
    background-color: rgba(248, 248, 248, 0.65);
    padding: 8px;
    overflow-y: hidden;
    max-height: 20vh;
    overflow-y: auto;
    transition: max-height 0.5s ease-in-out;
}

.profile-badge-card-ex h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 4px;
    margin: 0;
    font-size: 12px;
}

.profile {
    width: 100%;
    height: 36vh;
    margin-top: 7vh;
    color: #f8f8f8;
    padding: 5vh 0 8px 0;
}

.profile-main {
    background-color: #dcdcdc;
    height: auto !important;
}

#personal-info-icon {
    font-size: 4vw;
}

#profile-avatar {
    height: 24vw;
    width: 24vw;
    max-height: 200px;
    max-width: 200px;
    object-fit: cover;
    border-radius: 50%;
}

#header-avatar {
    height: 3vh;
    width: 3vh;
    object-fit: cover;
    border-radius: 50%;
}

#rewards-progress {
    position: fixed;
    width: 22vw;
    height: 22vw;
    right: 5%;
    top: 10%;
    background-color: rgba(0, 0, 0, 0);
    color: #f8f8f8;
}

#rewards-progress p {
    text-shadow: 1px 1px 2px #666666;
}

.new-rewards-desc {
    background-color: rgba(255, 255, 255, 0.6);
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 4px;
    margin: 0;
    font-size: 12px;
    color: #231F20;
    margin: 16px;
}

.new-rewards-content {
    display: grid;
    grid-template-columns: 30% 70%;
}

.contact-page-content {
    /* background-color: rgba(255,255,255,0.6); */
    margin-top: 8px;
}

.contact-page-content h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 4px;
    font-size: 12px;
    color: #231F20;
}

.contact-page-content a {
    color: #fff;
    font-weight: 300;
}

ion-input#orientation {
    border: 1px solid #231F20;
    border-radius: 5px;
    color: #231F20;
}

ion-input#leaderboard {
    background-color: #f8f8f8;
    height: 4vh;
    border-radius: 10px;
    -webkit-padding-start: 8px;
    --padding-start: 8px;
}
#skeleton-row {
    padding: 8px;
}
.loading-main-text {
    height: 24px;
    width: 100%;
    margin:auto;
    margin-bottom: 10px;
    background: #ececec;
    background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
    background-size: 250px;
    border-radius: 3px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
            animation: shine-loading-container-items 2s infinite ease-out;
  }

@-webkit-keyframes shine-loading-container-items {
    0% {
    background-position: -30vw;
    }
    40%, 100% {
    background-position: 90vw;
    }
}
@keyframes shine-loading-container-items {
    0% {
      background-position: -30vw;
    }
    40%, 100% {
      background-position: 90vw;
    }
  }
#leaderboard {
    height: 60vh;
    overflow-y: auto;
}

#leaderboard {
    height: 80vh;
    overflow-y: auto;
    margin-bottom: 16px;
}

#leaderboard p {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
}

#leaderboard div {
    background-color: #f8f8f8;
    border-bottom: 2px solid #c4c4c4;
}

.leaderboard-content {
    margin-bottom: 0%;
}

.new-rewards-card {
    border-radius: 10px;
    background-color: #c4c4c4;
    height: auto;
    width: 100%;
    overflow: hidden;
}

.top-three {
    background-color: #231F20;
    color: #fff;
    display: grid;
    grid-template-columns: 50% 40% 10%;
}

.top-three div {
    text-align: center;
}


/* .top-three div:nth-child(1) #leader{
    background-color: #c0c0c0;

}
.top-three div:nth-child(3) #leader{
    background-color: #CD7F32;

} */

.top-three div:nth-child(2) #leader {
    height: 20vw;
    width: 20vw;
    font-size: 48px;
    /* background-color: #fdc017; */
}

.top-three p {
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 4px 8px;
    margin: 0;
}

.top-three #leader {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    margin: 0;
    font-size: 24px;
    background-color: #99cc00;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
}

.my-rank p {
    font-family: "Montserrat";
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    margin: 0px;
}
.my-rank #leader {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    margin: 0;
    font-size: 24px;
    background-color: #ffc107;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
}

.leaderboard-columns {
    display: grid;
    grid-template-columns: 15% 40% 30% 15%;
    padding: 4px;
}

.leaderboard-row {
    display: grid;
    grid-template-columns: 15% 40% 30% 15%;
    padding: 4px;
    height: 6vh;
    align-items: center;
}

.featured-row {
    height: 8vh;
    background-color: #1a599c;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
    color: #fff;
}
.leader-row {
  color: #000;
  background-color: #eee;
}
/*

/*
.ft-leaderboard-row {
    display: flex;
    padding: 4px;
    height: 8vh;
    align-items: center;
    background-color: #1a599c;
    position: fixed;
    bottom: 6vh;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
    width: 200%;
    left: 0;
    color: #fff;
}

.leaderboard-row span {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
    color: #f14e4e;
    text-align: right;
} */

.leaderboard-row span {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
}
.feautured-row span {
    color: #fff;
}
.leader-row span {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
    color: #000000;
}
.ft-leaderboard-row p {
    padding: 2px 8px;
}
.leaderboard-card {
    margin-top: 16px;
    margin-bottom: 6vh;
    padding-bottom: 10px;
    height: 60vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
}

.leaderboard-card h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: left;
    padding: 4px 8px;
    margin: 0;
    font-size: 20px;
    background-color: #231F20;
    color: #fff;
}

.new-rewards-card h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: left;
    padding: 4px 8px;
    margin: 0;
    font-size: 20px;
    color: #f8f8f8;
    background-color: #231F20;
}

.grid33 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

:root[mode=ios] ion-label,
:root[mode=md] ion-label {
    --ion-font-family: 'Montserrat'!important;
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    margin: 4px;
}

:root[mode=ios] ion-list,
:root[mode=md] ion-list {
    --ion-background-color: #0E375A !important;
    --ion-background-color-rgb: 220, 220, 220 !important;
    background-color: #dcdcdc !important;
}

ion-toolbar {
    --background: #ffffff;
    color: #fff;
}

ion-card.ion-color .ion-inherit-color,
ion-card-header.ion-color .ion-inherit-color {
    color: #fff;
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    margin: 0px;
}

ion-card-header+.card-content-md {
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    margin: 0px;
}


/* :root[mode=ios] ion-item,
:root[mode=md] ion-item {
  --ion-color-shade: #231F20 !important;

}
ion-item .item-native div:nth-child(n){
    height: 32px !important;

} */

.news-tab {
    position: fixed;
    z-index: 3;
    top: 10%;
    width: 100%;
    transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
}

.news-tab-collapsed {
    top: 89%;
    width: 100%;
    position: fixed;
    transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
}

.news-collapsed {
    /* top: 93%; */
    width: 100%;
    /* position: fixed; */
    /* transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
    height: 0; */
}

.news {
    /* position: fixed;
    z-index: 3; */
    /* top: 53%; */
    width: 100%;
    /* transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
    bottom: 7vh; */
    /* height: auto; */
}

.news-header-expanded {
    display: grid;
    grid-template-columns: 50% 50%;
    /* position: fixed;
    z-index: 4; */
    /* bottom: 47%; */
    width: 100%;
    background-color: #fff;
    color: #231F20;
    /* transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out; */
}

.news-header {
    display: grid;
    grid-template-columns: 50% 50%;
    /* position: fixed;
    z-index: 4;
    bottom: 7vh;
    left: 0; */
    width: 100%;
    background-color: #fff;
    color: #231F20;
    /* transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out; */
}

.news-header h4 {
    margin: 0;
    padding: 5px 4px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin: 0;
    font-size: 4vw;
}

.news-header div:nth-child(2n) {
    text-align: right;
    padding: 5px 4px;
}

.news-header-expanded h4 {
    margin: 0;
    padding: 5px 4px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin: 0;
    font-size: 4vw;
}

.news-header-expanded div:nth-child(2n) {
    text-align: right;
    padding: 5px 4px;
}

.profile-row {
    display: grid;
    grid-template-columns: 50% 50%;
    border-bottom: 1px solid #231F20;
}

.profile-row div:nth-child(n) {
    padding: 0px 0px 8px 0px;
}

.profile-row div:nth-child(2n) {
    text-align: right;
}
.profile-row-button {
    display: inline;
    grid-template-columns: 50% 50%;
    border-bottom: 1px solid #231F20;
}
.profile-row-button div:nth-child(n) {
    padding: 0px 0px 8px 0px;
}
.profile-row-button div:nth-child(2n) {
    text-align: right;
}

.profile-banner {
    top: -20vh;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(10px) grayscale(1) brightness(0.6);
}

#login-header {
    text-align: center;
    padding: 10% 0;
}


/* ion-content#login {
    overflow-y: hidden;
} */

#login-row {
    display: grid;
    grid-template-columns: 90% 10%;
    padding: 4px 0px;
    border-bottom: 1px solid #fff;
}

#login-row ion-label {
    color: #fff;
}

#login ion-label {
    /* padding: 4px 16px;; */
    color: #fff;
}

#login ion-input {
    border-radius: 5px;
    border: 1px solid #fff;
}

#sign-up ion-input,
#apply ion-input,
ion-select {
    border-radius: 5px;
    border: 1px solid #231F20;
    color: #231F20;
}

#apply {
    background: white !important;
    border-radius: 20px;
    margin-top: 6vh;
}

#login-row ion-checkbox {
    float: right;
}

#login-button {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 8px 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 8px;
}

.signin {
    width: 35vw;
}

.markup-style {
    font-size: 20px;
}
ion-button.benefits-redirect {
    font-size: 14px;
}

button#western {
    color: #fff;
    background-color: #666666;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 8px 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 8px;
}

#login-signin {
    text-align: center;
    width: 100%;
}

#login-link {
    text-decoration: underline;
    margin: 8px;
}

#login-bg {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.8s linear;
    visibility: visible;
}

#login-bg-fade {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.5s, opacity 0.5s ease-in-out;
}

#edit-profile-avatar {
    height: 30vw;
    width: 30vw;
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.edit-profile {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 8px;
}

#edit-profileX {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0);
}

.gen-button {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 8px 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 8px;
}

.edit-info p {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
    margin: 4px;
}

.edit-info input {
    background-color: #ffffff;
    border-radius: 5px;
    width: 95%;
    height: 4vh;
    border: none;
    outline: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
}

#edit-profile-name {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: left;
    padding: 4px 8px;
    margin: 0;
    font-size: 20px;
    color: #f8f8f8;
    background-color: #231F20;
}

.edit-profile-card {
    border-radius: 10px;
    background-color: #c4c4c4;
    height: auto;
    width: 100%;
    overflow: hidden;
}

#login-square1 {
    height: 25vh;
    width: 25vh;
    /* background-color: rgba(153,204,0, 0.5); */
    background-color: rgba(128, 128, 128, 0.5);
    position: fixed;
    top: -3vh;
    left: -4vh;
    animation: float 8s infinite ease-in-out;
}

#login-square2 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(128, 128, 128, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 6vh;
    left: 12vh;
    animation: float 12s infinite ease-in-out;
}

#login-square3 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(128, 128, 128, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 28vh;
    left: 4vh;
    animation: float 12s infinite ease-in-out;
}

#login-square4 {
    height: 25vh;
    width: 25vh;
    /* background-color: rgba(153,204,0, 0.5); */
    background-color: rgba(102, 102, 102, 0.5);
    ;
    position: fixed;
    top: 46vh;
    left: -4vh;
    animation: float 8s infinite ease-in-out;
}

#login-square5 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(128, 128, 128, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 60vh;
    left: 12vh;
    animation: float 12s infinite ease-in-out;
}

#login-square6 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(128, 128, 128, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 80vh;
    left: -4vh;
    animation: float 12s infinite ease-in-out;
}

#login-square7 {
    height: 25vh;
    width: 25vh;
    /* background-color: rgba(102,102,102, 0.5); */
    background-color: rgba(102, 102, 102, 0.5);
    position: fixed;
    top: 0vh;
    right: -4vh;
    animation: float 8s infinite ease-in-out;
}

#login-square8 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(102, 102, 102, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 10vh;
    right: 12vh;
    animation: float 12s infinite ease-in-out;
}

#login-square9 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(128, 128, 128, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 35vh;
    right: 4vh;
    animation: float 12s infinite ease-in-out;
}

#login-square10 {
    height: 25vh;
    width: 25vh;
    /* background-color: rgba(102,102,102, 0.5); */
    background-color: rgba(102, 102, 102, 0.5);
    position: fixed;
    top: 58vh;
    right: -4vh;
    animation: float 8s infinite ease-in-out;
}

#login-square11 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(128, 128, 128, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 70vh;
    right: 12vh;
    animation: float 12s infinite ease-in-out;
}

#login-square12 {
    height: 25vh;
    width: 25vh;
    background-color: rgba(102, 102, 102, 0.5);
    /* background-color: rgba(115,153,0, 0.5); */
    position: fixed;
    top: 90vh;
    right: -4vh;
    animation: float 12s infinite ease-in-out;
}

@keyframes float {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, 0px);
    }
}

@keyframes span-out-right {
    0% {
        max-width: 0;
    }
    25% {
        max-width: 25%;
    }
    50% {
        max-width: 50%;
    }
    75% {
        max-width: 75%;
    }
    100% {
        max-width: auto;
    }
}


/* @keyframes spin {
    0% { transform: rotate3d(0deg);}
    100% { transform: rotate3d(180deg);}
} */


